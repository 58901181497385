<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="$router.push({ name: 'pressCenter' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>
      <a-row class="post-search" type="flex">
        <a-input
          v-model="search"
          class="search"
          placeholder="Поиск..."
          @input="SearchInput"
        />
        <a-button type="primary" @click="createFunc()">
          <a-icon type="plus" />
          {{ $t("Add") }}
        </a-button>
      </a-row>
    </a-row>
    <a-row type="flex" class="table__filtrs" justify="space-between">
      <div v-if="$route.params.id.toString() === '202'">
        <a-col :span="12" class="form_title"> {{ $t("SelectType") }}:</a-col>
        <a-col :span="1">
          <a-select
            v-model="filterByType"
            placeholder="Filter"
            class="table__parpage"
            option-filter-prop="children"
            style="width: 200px"
            allow-clear
            @change="handleFilterByType"
          >
            <a-select-option v-for="v of filters" :key="v.id" :value="v.id">
              {{ v.title[$i18n.locale] }}
            </a-select-option>
          </a-select>
        </a-col>
      </div>

      <a-col>
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="all-news-btn" @click="Allnews">
          Все
        </a-button>
        <a-button
          :disabled="cardsIsEmpty"
          type="primary"
          style="margin: 0 0 0 10px"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          Удалить
        </a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="categoryProps.results || []"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 3500, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'post-update',
              params: { id: item.id },
              query: { menu: $route.params.id, type: $route.query.type }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="news_title" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'post-update',
              params: { id: item.id },
              query: { menu: $route.params.id, type: $route.query.type }
            })
          "
        >
          <b>{{ item.title || "-" }}</b>
        </span>
        <!-- <a-tag v-if="item.title">
          <img
            width="14"
            class="flag-svg__tab"
            :src="contentLanguages[getFirstFulfilled(item.title).lang].flagSrc || ''"
            :alt="getFirstFulfilled(item.title).lang"
          />
          {{ contentLanguages[getFirstFulfilled(item.title).lang].abr }}
        </a-tag> -->
      </template>

      <template slot="news_type" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag v-if="checkType(item)">
          {{ checkType(item).title[$i18n.locale] }}
        </a-tag>
        <a-tag v-else> -</a-tag>
      </template>

      <template slot="author" slot-scope="item">
        {{ item.author || "-" }}
      </template>

      <template slot="title_languages" slot-scope="item">
        <template v-for="(title, key) in item.title_lang_support">
          <a-tag v-if="title" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="content_languages" slot-scope="item">
        <template v-for="(content, key) in item.content_lang_support">
          <a-tag v-if="content" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="pub_date" slot-scope="item">
        {{ moment(item.pub_date).format("LLL") }}
      </template>

      <template slot="files" slot-scope="item">
        {{ (item.images && item.images.length) || " - " }}
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="on_slider" slot-scope="item">
        <a-badge
          :status="item.on_slider ? 'success' : 'error'"
          :text="item.on_slider ? 'Да' : 'Нет'"
        />
      </template>

      <template slot="views" slot-scope="item">
        <a-icon type="eye" />
        {{ item.views }}
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template
        v-if="item && 'files' in item"
        slot="files_size"
        slot-scope="item"
      >
        {{
          bytesToSize(Object.values(item.files).reduce((a, b) => a + b.size, 0))
        }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push(
              {
                name: 'post-update',
                params: { id: item.id },
                query: { menu: $route.params.id, type: $route.query.type }
              },
              () => null
            )
          "
        >
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
          <!--<a-icon class="action-btns" style="margin: 0 10px" type="delete" />-->
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+categoryProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
import { CONTENT_LANGUAGES } from "@/constants"

export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableTitle"),
          key: "news_title",
          width: "40%",
          // fixed: "left",
          scopedSlots: { customRender: "news_title" }
        },
        {
          title: "Slug",
          key: "slug",
          width: "20%",
          // fixed: "left",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("Type"),
          key: "news_type",
          width: "15%",
          scopedSlots: { customRender: "news_type" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "15%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("OnSlider"),
          key: "on_slider",
          width: "15%",
          scopedSlots: { customRender: "on_slider" }
        },
        {
          title: this.$t("LangTitle"),
          key: "title_languages",
          width: "30%",
          scopedSlots: { customRender: "title_languages" }
        },
        {
          title: this.$t("LangContent"),
          key: "content_languages",
          width: "30%",
          scopedSlots: { customRender: "content_languages" }
        },
        {
          title: this.$t("ViewsCount"),
          key: "views",
          width: "15%",
          scopedSlots: { customRender: "views" }
        },
        {
          title: this.$t("Author"),
          key: "author",
          width: "15%",
          scopedSlots: { customRender: "author" }
        },
        {
          title: this.$t("DateOfPublication"),
          key: "pub_date",
          width: "20%",
          scopedSlots: { customRender: "pub_date" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "20%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "20%",
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      filters: [
        {
          id: 1,
          title: {
            oz: "Бошқалар",
            uz: "Boshqalar",
            ru: "Другие",
            en: "Others"
          }
        },
        {
          id: 2,
          title: {
            oz: "Кундалик маълумот",
            uz: "Kundalik ma'lumot",
            ru: "Ежедневная информация",
            en: "Daily info"
          }
        },
        {
          id: 3,
          title: {
            oz: "Эълон",
            uz: "E'lonlar",
            ru: "Объявления",
            en: "Announcements"
          }
        },
        {
          id: 4,
          title: {
            oz: "Шарҳлар",
            uz: "Sharhlar",
            ru: "Обзоры",
            en: "Reviews"
          }
        },
        {
          id: 7,
          title: {
            oz: "Анонслар",
            uz: "Anonslar",
            ru: "Анонсы",
            en: "Announs"
          }
        },
        {
          id: 8,
          title: {
            oz: "Кундалик маҳсулотлар нархи",
            uz: "Kundalik mahsulotlar narxi",
            ru: "Цены на продукты",
            en: "Prices of daily products"
          }
        }
      ],
      search: "",
      categoryProps: {
        results: [],
        count: 0
      },
      LimitList: [
        // {
        //   value: 0,
        //   title: "Все"
        // },
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        },
        {
          value: 50,
          title: "50"
        },
        {
          value: 100,
          title: "100"
        },
        {
          value: 150,
          title: "150"
        },
        {
          value: 200,
          title: "200"
        }
      ],
      cardsIsEmpty: true,
      perPage: 20,
      page: 1,
      offset: 0,
      filterByType: ""
    }
  },
  computed: {
    contentLanguages: () => CONTENT_LANGUAGES
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
      // console.log(this.selectedPosts)
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    handleFilterByType(v) {
      this.filterByType = v
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$store.dispatch("posts/fetch", {
          menu: this.$route.params && this.$route.params.id
        })
        if (r) {
          this.$set(this, "categoryProps", {
            results: r || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    SearchInput() {
      this.offset = 0
      this.fetchData()
    },
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
      if (bytes === 0) return "n/a"
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    getFirstFulfilled(fields) {
      let lang = this.$i18n.locale
      if (!fields) return {}
      if (fields[lang]) {
        return {
          text: fields[lang],
          lang: lang
        }
      } else {
        const lang = Object.keys(fields).find((l) => fields[l])
        if (!lang) {
          return {}
        }
        return {
          text: fields[lang],
          lang: lang
        }
      }
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const r = await this.$store.dispatch("posts/fetch", {
          menu: this.$route.params && this.$route.params.id,
          limit: this.perPage,
          offset: this.offset,
          search: this.search,
          type: this.filterByType
        })
        if (r) {
          this.$set(this, "categoryProps", {
            results: r.results || [],
            count: r.count
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$store.dispatch("posts/deletePost", i)
            if (res && res.status === 204) {
              this.$message.success("Успешно удалено")
            }
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch("posts/deletePost", id)
          if (res && res.status === 204) {
            this.$message.success("Успешно удалено")
          }
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    async createFunc() {
      /*let data = {
        menu: this.$route.params.id,
        is_active: false
      }
      if (this.$route.query.type) {
        data = { ...data, type: this.$route.query.type }
      }
      // let res = await this.$api.post("/admin/post/create/", data)
      await this.$router.push({
        name: "post-update",
        params: { id: res && res.data && res.data.id },
        query: { menu: this.$route.params.id }
      })*/

      await this.$router.push({
        name: "post-create",
        params: { id: this.$route.params.id },
        query: { type: this.$route.query.type }
      })
    },
    checkType(item) {
      return this.filters.find((filter) => filter.id === item.type)
    }
  }
}
</script>
